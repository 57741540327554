import $ from 'jquery'
import ekkoLightbox from 'ekko-lightbox/dist/ekko-lightbox.js'

export default class ImageGallery {
  constructor(el) {
    this.el = el
    let index = $(el).data('index')
    $(el).on('click', '[data-toggle="lightbox-' + index + '"]', function(event) {
        event.preventDefault();
        jQuery(this).ekkoLightbox();
    })
  }
}
