import $ from "jquery";

$(document).on("click", ".login-bankid", function (e) {
  e.preventDefault();
  $('[class^="status-"]').addClass("hide");
  var value = $("#edit-personnummer").val();
  if (value.match(/^\d{6}(\d{2})?-?\d{4}$/)) {
    value = value.replace("-", "");
    if (value.match(/^\d{10}$/)) {
      var entered_year = value.substring(0, 2);
      var currentTime = new Date();
      var fullyear = currentTime.getFullYear();
      var year = fullyear.toString();
      var current_year = year.substring(2, 2);
      var current_century = year.substring(0, 2);
      var prefix =
        entered_year < current_year ? current_century : current_century - 1;
      $("#edit-personnummer").val(prefix + value);
    }
    jQuery(".status-open-bankid").removeClass("hide");
    jQuery(".js-toggle-member-login-form").addClass("hide");
    form.submit();
    // $('.status-open-bankid').removeClass('hide');
    // $('#user-login-form').submit();
    //$('#loginform').addClass('hide');
  } else {
    if (value.length <= 0) $(".status-no-personnummer").removeClass("hide");
    else $(".status-invalid-personnummer").removeClass("hide");
    //$('#loginform').removeClass('hide');
  }
});
